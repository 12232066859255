import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';

import { Colors, FontSizePx } from '@kvika/audur-theme';
import {
  monthsString,
  getSliderYearsAndMonthsSuffix,
  getAudurFutureAccountInterestText,
  getMarketFutureAccountInterestText,
  getProjectedInflationRateText,
} from '@kvika/audur-strings';
import { getAverageMarketFutureInterestRate } from '@kvika/audur-prismic-utils';
import { projectedFutureAccountEarnings, getScaledCalcValue, getCalcPosition } from '@kvika/audur-utils';
import { toIskCurrencyString, toPercentageString } from '@kvika/string-utils';
import { AudurMarketInterestRatesFutureMarketInterestRates } from '@kvika/audur-prismic-types';
import { ProductSchema } from '@kvika/audur-api-types';
import styles from './CalculatorStyles.module.scss';
import ProjectedEarnings from './ProjectedEarnings';
import SliderRow from './SliderRow';
import { GridContainer, GridItem } from '../grid-container';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { stringToInt } from '../../utils/Utils';
import TextInfo from '../TextInfo';
import AudurTooltip from '../AudurTooltip';

type Props = {
  initialBalance?: number;
  product: ProductSchema;
  futureMarketInterestRates: AudurMarketInterestRatesFutureMarketInterestRates;
  inflationRate: number;
};

const FutureCalculator: FunctionComponent<Props> = ({
  initialBalance = 10000,
  product,
  futureMarketInterestRates,
  inflationRate,
}) => {
  const { calculatorStrings } = TranslatedStrings.is;
  const [originalBalance, setOriginalBalance] = useState<number | undefined>(initialBalance);
  const [originalBalancePos, setOriginalBalancePos] = useState<number | undefined>(0);
  const [months, setMonths] = useState<number | undefined>(36);
  const [projectedBalance, setProjectedBalance] = useState<string>('');
  const [projectedInterest, setProjectedInterest] = useState<string>('');
  const [avgMarketInterest, setAvgMarketInterest] = useState<string>('');

  const handleChangeBalanceSlider = (event: ChangeEvent<HTMLInputElement>) => {
    const position = stringToInt(event.target.value);
    const balance = getScaledCalcValue(position);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };

  const handleChangeBalanceInput = (balance: number | undefined) => {
    const position = getCalcPosition(balance);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };

  const handleChangeMonths = (event: ChangeEvent<HTMLInputElement>) => {
    setMonths(stringToInt(event.target.value));
  };

  const avgMarketInterestRate = getAverageMarketFutureInterestRate(futureMarketInterestRates);

  useEffect(() => {
    const projectedEarnings = projectedFutureAccountEarnings(
      product.interestRate,
      inflationRate,
      originalBalance ?? 0,
      months ?? 0
    );

    const projectedAvgMarketEarnings = projectedFutureAccountEarnings(
      avgMarketInterestRate,
      inflationRate,
      originalBalance ?? 0,
      months ?? 0
    );

    setProjectedBalance(toIskCurrencyString(projectedEarnings.balance, true));
    setProjectedInterest(toIskCurrencyString(projectedEarnings.interest, true));
    setAvgMarketInterest(toIskCurrencyString(projectedAvgMarketEarnings.interest, true));
  }, [originalBalance, months, product.interestRate, inflationRate, avgMarketInterestRate]);

  const clearState = () => {
    setOriginalBalance(10000);
    setOriginalBalancePos(getCalcPosition(10000));
    setMonths(36);
  };

  return (
    <GridContainer>
      <GridItem className={styles.gridColumnsLeft} style={{ justifyContent: 'flex-start' }}>
        <SliderRow
          title={calculatorStrings.originalBalance}
          value={originalBalancePos}
          valueToDisplay={originalBalance}
          valueSuffix="kr."
          min={0}
          max={200}
          step={1}
          onChangeSlider={(event) => handleChangeBalanceSlider(event)}
          onChangeInput={handleChangeBalanceInput}
        />
        <SliderRow
          title={calculatorStrings.time}
          value={months}
          valueSuffix={monthsString(months ?? 0)}
          longSuffix={getSliderYearsAndMonthsSuffix(months ?? 0)}
          isCurrency={false}
          min={36} // Min 3 years
          max={216} // Max 18 years
          onChangeSlider={(event) => handleChangeMonths(event)}
          onChangeInput={setMonths}
        />
      </GridItem>
      <GridItem className={styles.gridColumnsRight} style={{ justifyContent: 'flex-start' }}>
        {product ? (
          <ProjectedEarnings
            selectedProduct={product}
            projectedBalance={projectedBalance}
            projectedInterest={projectedInterest}
            averageMarketInterest={avgMarketInterest}
            averageMarketInterestRate={avgMarketInterestRate}
            onClearButtonClick={clearState}
            audurInterestTooltipText={getAudurFutureAccountInterestText(product.interestRate)}
            marketInterestTooltipText={getMarketFutureAccountInterestText(avgMarketInterestRate)}
          >
            <div className={styles.textInfoContainer}>
              <div className={styles.textWithTooltip}>
                <TextInfo
                  color={Colors.Silver}
                  text={calculatorStrings.projectedYearlyInflation}
                  fontSize={FontSizePx.BodySmall}
                />
                <AudurTooltip
                  heading={calculatorStrings.marketInterestRate}
                  text={getProjectedInflationRateText(inflationRate)}
                />
              </div>
              <TextInfo
                text={toPercentageString(inflationRate)}
                color={Colors.Silver}
                bold
                fontSize={FontSizePx.Body}
              />
            </div>
          </ProjectedEarnings>
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

export default FutureCalculator;
