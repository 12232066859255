import { CSSProperties } from 'react';

import { toPercentageString } from '@kvika/string-utils';
import { FontSizePx, Spacing, Colors } from '@kvika/audur-theme';
import { ProductSchema, ProductType } from '@kvika/audur-api-types';
import styles from './ProjectedEarnings.module.scss';
import AudurTooltip from '../AudurTooltip';
import TextInfo from '../TextInfo';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { AudurButton, AudurButtonLink } from '../audur-buttons';
import { Route } from '../../navigation/Routes';
import { SegmentTrackingId, trackEvent } from '../../utils/Analytics';

const { calculatorStrings, navigationStrings } = TranslatedStrings.is;

type Props = {
  selectedProduct: ProductSchema;
  projectedBalance: string;
  projectedInterest: string;
  averageMarketInterest: string;
  averageMarketInterestRate: number;
  onClearButtonClick(): void;
  style?: CSSProperties;
  audurInterestTooltipText: string;
  marketInterestTooltipText: string;
  children?: JSX.Element;
};

const ProjectedEarnings = ({
  selectedProduct,
  projectedBalance,
  projectedInterest,
  averageMarketInterest,
  averageMarketInterestRate,
  onClearButtonClick,
  style,
  audurInterestTooltipText,
  marketInterestTooltipText,
  children,
}: Props) => {
  const isTermDeposit = selectedProduct.type === ProductType.TermDeposit;

  return (
    <div className={styles.container} style={style}>
      <div className={styles.headerContainer} style={isTermDeposit ? { marginBottom: Spacing.Large } : {}}>
        <h4>{calculatorStrings.interestRoute}</h4>
        <AudurButton
          title={calculatorStrings.clear}
          onClick={onClearButtonClick}
          style={{ backgroundColor: Colors.Orange, width: 100, height: 40 }}
        >
          {calculatorStrings.clear}
        </AudurButton>
      </div>
      {!isTermDeposit && (
        <>
          <div className={styles.textInfoContainer} style={{ marginTop: Spacing.Large }}>
            <TextInfo text={calculatorStrings.projectedEarnings} color={Colors.Silver} fontSize={FontSizePx.Body} />
            <TextInfo text={projectedBalance} color={Colors.Silver} bold fontSize={FontSizePx.BodyLarge} />
          </div>
          <div className={styles.textInfoContainer} style={{ marginTop: Spacing.Small }}>
            <TextInfo text={calculatorStrings.projectedInterests} color={Colors.Silver} fontSize={FontSizePx.Body} />
            <TextInfo text={projectedInterest} color={Colors.Silver} bold fontSize={FontSizePx.BodyLarge} />
          </div>
          <div className={styles.divider} />
        </>
      )}
      <div className={styles.textInfoContainer}>
        <div className={styles.textWithTooltip}>
          <TextInfo
            text={`${calculatorStrings.audurInterestRate} (${toPercentageString(selectedProduct.interestRate)})`}
            color={Colors.Silver}
            fontSize={isTermDeposit ? FontSizePx.Body : FontSizePx.BodySmall}
          />
          <AudurTooltip heading={calculatorStrings.interest} text={audurInterestTooltipText} />
        </div>
        <TextInfo text={projectedInterest} bold color={Colors.Silver} fontSize={FontSizePx.Body} />
      </div>
      <div className={styles.textInfoContainer}>
        <div className={styles.textWithTooltip}>
          <TextInfo
            color={Colors.Silver}
            text={`${calculatorStrings.marketInterestRate} (${toPercentageString(averageMarketInterestRate)})`}
            fontSize={isTermDeposit ? FontSizePx.Body : FontSizePx.BodySmall}
          />
          <AudurTooltip heading={calculatorStrings.marketInterestRate} text={marketInterestTooltipText} />
        </div>
        <TextInfo text={averageMarketInterest} color={Colors.Silver} bold fontSize={FontSizePx.Body} />
      </div>
      {children}
      <div className={styles.createAccountButtonContainer}>
        <AudurButtonLink
          href={Route.AudurWebBank}
          borderColor={Colors.Silver}
          style={{ flex: 1 }}
          onClick={() => {
            trackEvent({ event: SegmentTrackingId.NetbankiButtonClicked });
          }}
        >
          {navigationStrings.createAccount}
        </AudurButtonLink>
      </div>
    </div>
  );
};

export default ProjectedEarnings;
