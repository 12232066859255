import { AudurContentPageEditorialContentGroup, AudurEditorialContent } from '@kvika/audur-prismic-types';
import { Colors } from '@kvika/audur-theme';
import { PrismicRichText } from '@prismicio/react';
import { useEffect, useState } from 'react';
import { BannerTab, ColumnClass } from '../../types/Types';
import {
  getHomeLottieImageDimensions,
  getHomeLottieImageOffset,
  getPrismicHref,
  getPrismicTitle,
} from '../../utils/Utils';
import { AudurButtonLink } from '../audur-buttons';
import { GridItem } from '../grid-container';
import LottieCard from '../LottieCard';
import styles from './BannerCarousel.module.scss';
import CarouselTabs from './CarouselTabs';
import MotionContainer from './MotionContainer';

type Props = {
  groups: AudurContentPageEditorialContentGroup[];
  isLottiePaused: boolean;
  isMobile: boolean;
  isTablet: boolean;
  color: typeof Colors;
  valueOrDefault(value: string, defaultValue: string): string;
};

enum LOTTIE_IMAGE_NAME {
  'apple' = 'apple',
  'beach' = 'beach',
  'space' = 'space',
  'pool' = 'pool',
  'company' = 'company',
}

const LOTTIE_IMAGE_URL = {
  [LOTTIE_IMAGE_NAME.apple]: 'lottiefiles/banner-apple.json',
  [LOTTIE_IMAGE_NAME.beach]: 'lottiefiles/Banner-beach-new-colors-2.json',
  [LOTTIE_IMAGE_NAME.space]: 'lottiefiles/audur-fyrirtaeki-new-color.json',
  [LOTTIE_IMAGE_NAME.pool]: 'lottiefiles/banner-pool.json',
  [LOTTIE_IMAGE_NAME.company]: 'lottiefiles/Audur-Eldflaug-lottie-2.json',
};

const BannerCarousel = ({ groups, isLottiePaused, isMobile, isTablet, color, valueOrDefault }: Props) => {
  const [selectedBannerId, setSelectedBannerId] = useState(BannerTab.FirstBanner);
  const [currentBanner, setCurrentBanner] = useState<AudurEditorialContent>(
    groups[BannerTab.FirstBanner].contentPageEditorial as AudurEditorialContent
  );

  const [accentColor, setAccentColor] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const absContentPosition = isMobile ? 400 : 1300;

  useEffect(() => {
    setCurrentBanner(groups[selectedBannerId].contentPageEditorial as AudurEditorialContent);
    if (selectedBannerId === BannerTab.ThirdBanner) {
      setAccentColor(color.BabyBlue);
    } else if (selectedBannerId === BannerTab.SecondBanner) {
      setAccentColor(color.Red);
    } else {
      setAccentColor(color.Yellow);
    }
  }, [color.BabyBlue, color.Red, color.Yellow, groups, selectedBannerId]);

  useEffect(() => {
    const next = (selectedBannerId + 1) % groups.length;
    const timer = setTimeout(() => onChangeBanner(next), 8000);
    return () => clearTimeout(timer);
  });

  const getClass = () => {
    if (selectedBannerId === BannerTab.ThirdBanner) {
      return styles.blueAccentColor;
    }
    if (selectedBannerId === BannerTab.SecondBanner) {
      return styles.redAccentColor;
    }
    return styles.yellowAccentColor;
  };

  const onChangeBanner = (index: number) => {
    setIsVisible(false);

    const timer = setTimeout(() => {
      setSelectedBannerId(index);
      setIsVisible(true);
    }, 400);

    return () => clearTimeout(timer);
  };
  const { height } = getHomeLottieImageDimensions(
    isMobile,
    isTablet,
    currentBanner.imageWidth ?? 0,
    currentBanner.imageHeight ?? 0
  );

  const getImagePath = (imageName?: LOTTIE_IMAGE_NAME) => {
    if (imageName) {
      return LOTTIE_IMAGE_URL[imageName];
    }
    return '';
  };

  return (
    <>
      <GridItem className={`${ColumnClass.Right} ${styles.leftGridItem}`}>
        <MotionContainer isVisible={isVisible} xPos={absContentPosition}>
          <LottieCard
            loop
            paused={isLottiePaused}
            imagePath={getImagePath(currentBanner.lottieImageName as LOTTIE_IMAGE_NAME)}
            height={height}
            imageOffset={getHomeLottieImageOffset(isMobile, isTablet)}
            lottieClassName={styles.lottieBannerResponsive}
          />
        </MotionContainer>
      </GridItem>
      <GridItem className={`${ColumnClass.Left} ${styles.rightGridItem}`}>
        <CarouselTabs
          banners={groups}
          selectedBannerId={selectedBannerId}
          accentColor={accentColor}
          onChangeBanner={onChangeBanner}
          className={styles.tabsWrapper}
        />
        <MotionContainer isVisible={isVisible} xPos={-absContentPosition} className={styles.motionContainer}>
          <div className={`${getClass()}`}>
            <PrismicRichText field={currentBanner.title} />
            <PrismicRichText field={currentBanner.description} />
          </div>
        </MotionContainer>
        <AudurButtonLink
          key="button-link-group"
          href={valueOrDefault(getPrismicHref(currentBanner.linkGroup), '/')}
          fillColor={color.Beige}
          openInNewTab={false}
          className={styles.button}
        >
          {valueOrDefault(getPrismicTitle(currentBanner.linkGroup), '/')}
        </AudurButtonLink>
      </GridItem>
    </>
  );
};

export default BannerCarousel;
