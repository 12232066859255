import { motion } from 'framer-motion';
import { Colors } from '@kvika/audur-theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Path = (props: any) => (
  <motion.path
    fill={Colors.Silver}
    strokeWidth="3"
    strokeOpacity={1}
    stroke={Colors.Silver}
    strokeLinecap="round"
    {...props}
  />
);
