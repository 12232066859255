import Image from 'next/image';
import React from 'react';

import { Colors } from '@kvika/audur-theme';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { easeInOut } from '@popmotion/easing';
import { useMediaQuery } from 'react-responsive';
import { AudurHeader, AudurLink as AudurLinkType } from '@kvika/audur-prismic-types';
import { valueOrDefault } from '@kvika/audur-prismic-utils';

import styles from './Topnavigation.module.scss';
import HamburgerMenu from './HamburgerMenu';
import AudurLink from '../AudurLink';
import HeaderLinks from './HeaderLinks';
import { AudurButtonLink } from '../audur-buttons';
import { Route } from '../../navigation/Routes';
import { getNavigationHeaderY } from '../../utils/Constants';
import { SegmentTrackingId, trackEvent } from '../../utils/Analytics';
import { LinkStyle } from '../../types/Types';

type Props = {
  onMobileMenuToggle(): void;
  isMobileMenuOpen?: boolean;
  header: AudurHeader;
};

const TopNavigation = ({ onMobileMenuToggle, isMobileMenuOpen = false, header }: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const logoWidths = isMobile ? [140, 120] : [160, 130];
  const { scrollYProgress } = useViewportScroll();
  const transformedHeaderY = useTransform(scrollYProgress, [0, 0.1], [0, getNavigationHeaderY(isMobile) / 2], {
    ease: easeInOut,
  });

  const transformedLogoWidth = useTransform(scrollYProgress, [0, 0.1], logoWidths, {
    ease: easeInOut,
  });

  return (
    <div id="topNavigation" className={styles.outerContainer}>
      <motion.div
        className={styles.navigationContainer}
        style={{ translateY: transformedHeaderY }}
        suppressHydrationWarning
      >
        <motion.div className={styles.logoContainer} style={{ width: transformedLogoWidth }} suppressHydrationWarning>
          <AudurLink href={Route.Home} className={styles.logo}>
            <Image
              src={header.image.url}
              alt="Auður logo"
              width={header.image.dimensions.width}
              height={header.image.dimensions.height}
              priority
            />
          </AudurLink>
        </motion.div>
        <HeaderLinks links={header.link_group ?? []} />
        <div className={styles.navigationButtonContent}>
          <div className={styles.loginButtons}>
            {header.button_group?.map((item, index) => {
              const button = item.button as AudurLinkType;
              return (
                <AudurButtonLink
                  fillColor={button.linkStyle === LinkStyle.Button ? Colors.White : Colors.DarkGray}
                  borderColor={button.linkStyle === LinkStyle.DarkButton ? Colors.White : undefined}
                  href={valueOrDefault(button.linkUrl, '/')}
                  className={button.linkStyle === LinkStyle.Button ? styles.whiteFill : styles.darkFill}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  {valueOrDefault(button.linkTitle, '')}
                </AudurButtonLink>
              );
            })}
          </div>
          <div className={styles.mobileLoginButton}>
            <AudurButtonLink
              href={Route.AudurWebBank}
              fillColor={Colors.Red}
              style={{ width: 48, height: 48, paddingTop: 4 }}
              onClick={() => {
                trackEvent({ event: SegmentTrackingId.NetbankiButtonClicked });
              }}
            >
              <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16ZM14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM4.9 5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7H4.9V5ZM14 19H2V9H14V19Z"
                  fill="#F8ECDC"
                />
              </svg>
            </AudurButtonLink>
          </div>
          <div className={styles.mobileHamburgerButton}>
            <HamburgerMenu
              toggleMenuOpen={onMobileMenuToggle}
              buttonColor={Colors.Orange}
              isMenuOpen={isMobileMenuOpen}
            />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default TopNavigation;
